function updateLocalTimes() {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(`Browser Time Zone: ${browserTimeZone}`); // Debugging line

    document.querySelectorAll('.local-time').forEach((element: HTMLElement, index: number) => {
        const dataTime = element.getAttribute('data-time');
        if (dataTime) {
            const eventTime = new Date(dataTime);

            if (index === 0) { // Assuming the first .local-time is the date-time element
                const optionsDateTime = {
                    timeZone: browserTimeZone,
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                (element as HTMLElement).textContent = eventTime.toLocaleString([], optionsDateTime);
                console.log(`Updated date-time for element: ${element.textContent}`); // Debugging line
            } else { // For other elements
                const optionsTime = {
                    timeZone: browserTimeZone,
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                (element as HTMLElement).textContent = eventTime.toLocaleTimeString([], optionsTime);
                console.log(`Updated time for element: ${element.textContent}`); // Debugging line
            }
        }
    });
}

function reloadPage() {
    console.log('Reloading page due to flash notice.'); // Debugging line
    window.location.reload();
}

function observeFlashNotice() {
    const targetNode = document.body;
    const config = { childList: true, subtree: true };

    const callback = (mutationsList: MutationRecord[]) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const flashNotice = document.querySelector('.flash-notice');
                if (flashNotice) {
                    reloadPage();
                    return; // Stop observing after detecting the flash notice
                }
            }
        }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
}

function initialize() {
    updateLocalTimes();
    observeFlashNotice();
}

window.onload = initialize;
