import { Controller } from 'stimulus';
import * as CronofyElements from 'cronofy-elements';

export default class SchedulerAvailabilityController extends Controller {
  static targets = ["elementDestination"]
  static values = { clientId: String, elementToken: String, timezone: String, ruleId: String, hideCalendars: Boolean }

  declare elementDestinationTarget: HTMLElement
  declare clientIdValue: string
  declare elementTokenValue: string
  declare timezoneValue: string
  declare ruleIdValue: string
  declare hideCalendarsValue: boolean

  connect() {
    if(this.hideCalendarsValue) {
      this.element.classList.add('hide-calendar-picker')
    }

    CronofyElements.AvailabilityRules({
      target_id: this.elementDestinationTarget.id,
      availability_rule_id: this.ruleIdValue,
      config: {
        start_time: "05:00",
        end_time: "24:00",
        duration: 30,
        logs: 'info',
      },
      element_token: this.elementTokenValue,
      styles: {
        prefix: 'scheduler-availability',
      },
      tzid: this.timezoneValue,
    })

    setTimeout(()=> {
      this.element.querySelector('.scheduler-availability__calendars').setAttribute('aria-hidden', 'true')
    }, 2000)
  }
}