// app/javascript/controllers/iframe_modal_controller.js

import { Controller } from "stimulus";

export default class IframeModalController extends Controller {
    static targets = ["modal", "modalContent", "copyButton"];

    connect() {
        this.triggerContent = this.triggerTarget.innerHTML
        this.element.insertAdjacentHTML('afterend', '<textarea readonly aria-hidden="true" class="offscreen">' + this.copyValue + '</textarea>')
        this.source = this.element.nextElementSibling as HTMLTextAreaElement;
    }


    copy(event) {
        event.preventDefault();
        console.log("me estoy clickeando?")
        const iframeCode = event.target.dataset.clipboardCopyValue;
        this.modalContentTarget.innerHTML = iframeCode;
        this.modalTarget.style.display = 'block';
    }

    copyFromModal() {
        event.preventDefault();
        const textToCopy = this.modalContentTarget.textContent;
        navigator.clipboard.writeText(textToCopy).then(() => {
            // You can add some feedback here like changing the button text to "Copied!"
            // Restore original button text after some delay
        });
        this.modalTarget.style.display = 'none';
    }

    close() {
        this.modalTarget.style.display = 'none';
    }
}
