import { Controller } from 'stimulus';
import * as CronofyElements from 'cronofy-elements';

export default class SchedulerCalendarsController extends Controller {
  static targets = ["elementDestination"]
  static values = { redirectUri: String, clientId: String, elementToken: String, scopes: String }

  declare elementDestinationTarget: HTMLElement
  declare redirectUriValue: string
  declare clientIdValue: string
  declare elementTokenValue: string
  declare scopesValue: string

  connect() {
    CronofyElements.CalendarSync({
      target_id: this.elementDestinationTarget.id,
      authorization: {
        redirect_uri: this.redirectUriValue,
        scope: this.scopesValue,
        client_id: this.clientIdValue,
      },
      element_token: this.elementTokenValue,
      styles: {
        prefix: 'scheduler'
      },
      single_profile: true,
    })
  }
}