import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tzMappings: Object,
  }

  connect() {
    this.element.textContent = this.tzMappingsValue[Intl.DateTimeFormat().resolvedOptions().timeZone];
  }
}
